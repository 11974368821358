import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import ProductPreview from "../components/products/product-preview"
import HeroSection from "../components/hero-section"
import ProductIcons from "../components/products/product-icons"
import SEO from "../components/seo"
import FaqSection from "../components/faq-section"
import StickyMobileCta from "./../components/call-to-action-qualify/sticky-mobile-cta"

export default function Products({
  data: { productsQuery, faqsQuery, allFile },
}) {
  const { edges: images } = allFile
  const hero = images.filter(({ node }) => node.name.includes("hero"))[0]
  const cta = images.filter(({ node }) => node.name.includes("cta"))[0]
  const { edges: products } = productsQuery
  const { edges: faqs } = faqsQuery

  //alert (JSON.stringify(products))

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    xhr.send(data);
}
  useEffect(() => {
    addWebEvent("visit", "products")
  },[]);



  //API REQUEST WAIT RESPOND TO MOVE FORWARD
  function httpRequest(address, reqType, asyncProc) {
    var req = typeof window !== "undefined" ? new XMLHttpRequest() : ""
    if (asyncProc) { 
      req.onreadystatechange = function() { 
        if (this.readyState == 4) {
          asyncProc(this);
        } 
      };
    }
    typeof window !== "undefined" ? req.open(reqType, address, !(!asyncProc)) : ""
    typeof window !== "undefined" ? req.send() : ""
    return req;
    }

  var req = httpRequest("https://api.checksolarcost.com/v1/getWebsiteData", "POST");
  const response = req.responseText;
  const products_obj = typeof window !== "undefined" ? JSON.parse(response).products : ""
  const product_content = typeof window !== "undefined" ? products_obj.map(product => { return (<ProductPreview product={product}/>) }) : ""

  //alert(JSON.stringify(products_obj))

  return (
    <Layout>
      <SEO title="Products" />
      <HeroSection
        className="text-white flex justify-center items-center"
        fluid={hero.node.childImageSharp.fluid}
        alt="Modal Products hero"
      >
        <h1 className="text-center font-light tracking-wide px-6">
          Make Yourself <br />
          <span className="font-bradford">a home</span>
        </h1>
      </HeroSection>

      <StickyMobileCta></StickyMobileCta>

      <section className="relative">

        {product_content}
        
      </section>

      <ProductIcons></ProductIcons>
      <FaqSection faqs={faqs}></FaqSection>

      <section className="relative" style={{ height: "65vh" }}>
        <Image
          className="absolute h-full w-screen"
          objectFit="cover"
          fluid={cta.node.childImageSharp.fluid}
          alt="Modal Products cta"
        />
        <div className="absolute inset-0 absolute inset-0 flex justify-center items-center text-center z-10">
          <div>
            <h3 className="m-auto max-w-md tracking-wide font-light text-white">
              Who <span className="font-bradford">we </span>are.
            </h3>
            <p className="pt-4 pb-12 lg:px-0 px-6 max-w-xl m-auto tracking-wide font-light text-white">
              Our minimal spaces combine thoughtful design with quality
              materials–learn more about what makes Modal different.
            </p>
            <Link
              to="/about"
              className="transition transition-delay-longer bg-white text-gray-600 border border-white hover:text-white hover:bg-transparent w-40 mt-12 px-8 py-3 text-center font-bradford1 tracking-wide modal-button"
            >
              About Us
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ProductsPageQuery {
    productsQuery: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "products" } } }
      sort: { fields: frontmatter___title }
    ) {
      edges {
        node {
          frontmatter {
            title
            tagline
            description
            featuredInfo {
              image {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 50) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              description
            }
            type
          }
          fields {
            slug
          }
        }
      }
    }
    faqsQuery: allMarkdownRemark(
      filter: { frontmatter: { location: { eq: "products" } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            answer
            question
            order
          }
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/products/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
