import React, { useState, useEffect } from "react"
import { Link} from "gatsby"
import Image from "gatsby-image"

export default function ProductPreview( product ) {
  //const { title, type, tagline, description, slug } = product
  //const contentClass = flip ? "md:ml-16" : "md:mr-16"

  //alert(JSON.stringify(product.product))
  var product = product.product;
  //alert(product.link)

  //useEffect(() => {

  var bg_color = "";
  var reverse = "";

  if ( product.link == "modal-pod") {
    var bg_color = "white";
    //var reverse = "row-reverse";
  } 

  if ( product.link == "modal-01") {
    var bg_color = "white";
    //var reverse = "row-reverse";

  }

  //});
  
  return (
    <div
      id="position"
      className="flex flex-wrap-reverse items-center justify-between bg-gray-100 lg:p-24 lg:py-48 px-6 py-10 md:flex-no-wrap"
      style = {{backgroundColor: bg_color, flexDirection: reverse}}
    >
      <div //className={`w-full flex flex-col m-0 md:w-1/3 ${contentClass}`}>
          className={`w-full flex flex-col m-0 md:w-1/3`}>
        <p className="font-bradford text-gray-500 small-p pt-10">{product.tagline}</p>
        <h2 className="font-maxeville mt-3">{product.title}</h2>

        <p className="mt-6 font-light tracking-wide text-gray-500">
          {product.description}
        </p>

        <Link
          className="transition transition-delay-longer my-8 flex justify-center items-center w-40 mt-12 px-8 py-3 border border-green-900 bg-green-900 hover:bg-transparent hover:text-green-900 text-white font-bradford1 modal-button"
          to={`/products/${product.link}`}
        >
          Learn More
        </Link>
      </div>

      <img src={product.main_webpic} className="w-full md:w-2/3" objectFit="cover"></img>

    </div>
  )
}
